import React, { useEffect} from "react";
import { useStyles } from "./Loading.styled";
import axios from 'axios'
import Airtable from 'airtable'
import short from 'short-uuid'

export default function Loading({step, setStep, subStep, setSubStep, m1, m2, m3, m4, m5, m6, img1, img2, img3, img4, setLaceTint, setLaceTintDescription, setCapSize, posted, setPosted}){
    const classes = useStyles();
    
    const imagesData=[img1, img2, img3, img4]
    var base = new Airtable({apiKey: process.env.REACT_APP_APIKEY,}).base('appxQH9SPqXyNrcg4');
    const postImages = async() => {
        const data={
          image1: img1,
          image2: img2,
          image3: img3,
          image4: img4,
          email: "naaf-conference",
        }
       await axios.post(`${process.env.REACT_APP_BACKEND}api/ai/imageCollect/`, data)
        .then((res)=>{
           const airtable_data = {
            "UUID": "naaf-conference-" + short.generate(),
            "images": res.data.s3_bucket_folder,
            "Circumference": parseFloat(m1),
            "Front to nape": parseFloat(m2),
            "Ear to ear across forehead": parseFloat(m3),
            "Ear to ear over top": parseFloat(m4),
            "Temple to temple round back": parseFloat(m5),
            "Nape of neck": parseFloat(m6),
           }
           base('Sizing Data').create([
               {
                 "fields": airtable_data,
               }
             ], function(err, records) {
              if (err) {
                console.error(err)
                return;
              }
              records.forEach(function(record) {
                //console.log(record.getId());
            });
          })
        }).catch((err)=>{
          console.log(err)
        })
        setStep(step+1)
        setSubStep(subStep+1)
    }
    useEffect(() => {
        postImages()
    }, [])

    return (
        <div style={{width: '100%', marginTop: '30vh'}}>
            <div className={`${classes.spin} ${classes.title}`} style={{width: '10px', marginLeft: '50%'}}>
                ·   ·
            </div>
            <div className={classes.title} style={{marginTop: '20px', marginRight: 'auto', marginLeft: 'auto', textAlign: 'center'}}>
               Processing your images...
            </div>
        </div>
    )
}
