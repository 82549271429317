import React from 'react'
import {Routes,Route ,  BrowserRouter as Router} from 'react-router-dom'
import Measuring from './components/Measuring'

export default function App() {

  return (
    <Router>
    <Routes> 
        <Route exact path="/" element={<Measuring/>}/>
    </Routes>
    </Router>
  )
}
