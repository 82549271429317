import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import ParfaitLogo from '../../assets/ParfaitLogo.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Link from '@material-ui/core/Link';
import { useStyles } from "./Navbar.styled";

const Navbar = () => {

  const classes = useStyles();

  return (
    <div  className={classes.AppBar} position="static">
      <Container maxWidth="xl" >
        <Toolbar className={classes.ToolBar} disableGutters >
        <img src={ParfaitLogo} style={{"maxWidth":"100%",  "width": "auto", "height": "auto",  "marginTop":"5px"}}></img>
        <div className={classes.SocialIcons}>
            <Link className={classes.Link} href="https://www.instagram.com/my.parfait/"  target="_blank"><InstagramIcon></InstagramIcon></Link>
            <Link className={classes.Link} href="https://www.youtube.com/channel/UCdrze0SGd9AelWGlvdmE6hw"  target="_blank"> <YouTubeIcon></YouTubeIcon></Link>
            <Link className={classes.Link} href="https://www.linkedin.com/company/parfaithair/mycompany/?viewAsMember=true"  target="_blank">  <LinkedInIcon></LinkedInIcon></Link>
        </div>
        </Toolbar>
        
      </Container>
    </div>
  );
};
export default Navbar;