import React, {useState}from "react";
import { useStyles } from "./GetReady.styled";
import useWindowDimensions from '../useWindowDimensions';

export default function GetReady({step, setStep, subStep, setSubStep}){
    const image = '/images/measuring.png';
    const classes = useStyles();
    const {width} = useWindowDimensions()
    const [message, setMessage]=useState("")
    const [uncheckedMessage, setUncheckedMessage]=useState("")
    const [checked, setChecked]= useState(false)
    const isMobile = width <= 600
    return (
        <div className={classes.containerImg} >
            <div className={classes.container}>
                <div className={classes.title}>
                    Step 1: Take my measurements!
                </div>
                <div>
                  <video className={classes.imageDesktop} playsInline webkit-playsinline autoPlay loop muted>
                    <source src="https://elasticbeanstalk-us-east-1-450454924520.s3.amazonaws.com/Ani+CC+fitting.mp4" type="video/mp4"/>
                  </video>
                </div>
                <div className={classes.description}>
                    At Parfait, we use technology to ensure the correct lace tint and custom cap size for you by simply taking a few selfies. 
                    We’re putting our AI to the test to ensure an experience like never before.
                    You’ll take 4 selfies using a card as your ruler to capture your measurements.
                    We can’t wait to see you #realizeyourbeautyvision!
                </div>
                <div >
                    
                    <div className={'agree'}>
                        <p className={classes.warning}>{uncheckedMessage}</p>
                    <input
                            type="checkbox"
                            onClick={() =>{
                            setChecked(!checked)
                            }
                        }
                            name="checked"
                            id="agree"
                        />
                        <label htmlFor="agree">
                        &nbsp; By using our application, you agree 
                        </label>
                        <br/>
                        to our <a href="https://www.myparfait.com/policies/privacy-policy">privacy policy</a> & <a href="https://www.myparfait.com/policies/terms-of-service">terms and conditions</a>.
                        
        </div>
                </div>
            <div className={classes.button} onClick={() => {
                if(checked===false){
                    setUncheckedMessage("You must agree to the privary policy and terms and conditions to submit data to this application.")
                }
                else{
                    setStep(step + 1);
                    setSubStep(subStep + 1);
                }
                }}>
                    {isMobile ? "Customize your wig" : "Let’s go!"}
            </div>

            </div>
            
        </div>
    )
}
