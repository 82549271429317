import React from "react";
import { useStyles } from "./ManualMeasure.styled";
import useWindowDimensions from '../useWindowDimensions';

export default function ManualMeasure({step, setStep, subStep, setSubStep, m1, m2, m3, m4, m5, m6, setM1, setM2, setM3, setM4, setM5, setM6}){
    const classes = useStyles();
    const {width} = useWindowDimensions()
 
    return (
        <div className={classes.container}>
            <div className={classes.block}>
                <div className={classes.title} >
                    Step 1: Manual Data Collection 
                </div>
                <div className={classes.textDesktop}>
                    <div className={classes.description} >
                        First, we will take 6 measurements representing your 3D head dimensions.  
                        <br /> Please ask a Parfait associate to help you collect these measurements with a soft measuring tape.
                    </div>
                    <br />
                </div>
                <div className="row">
                <div>
                     <label className={`${classes.description} ${classes.label}`}>1. Circumference </label> &nbsp; &nbsp;
                     <input 
                        name="m1"
                        value={m1}
                        onChange={e => { e.preventDefault(); setM1(e.target.value) }}
                        required
                    />
                </div>
                <div>
                     <label className={`${classes.description} ${classes.label}`}>2. Front to nape </label> &nbsp; &nbsp;
                     <input 
                        name="m2"
                        value={m2}
                        onChange={e => { e.preventDefault(); setM2(e.target.value) }}
                        required
                    />
                </div>
                <div>
                     <label className={`${classes.description} ${classes.label}`}>3. Ear to ear across forehead</label> &nbsp; &nbsp;
                     <input 
                        name="m3"
                        value={m3}
                        onChange={e => { e.preventDefault(); setM3(e.target.value) }}
                        required
                    />
                </div>
                <div>
                     <label className={`${classes.description} ${classes.label}`}>4. Ear to ear over top </label> &nbsp; &nbsp;
                     <input 
                        name="m4"
                        value={m4}
                        onChange={e => { e.preventDefault(); setM4(e.target.value) }}
                        required
                    />
                </div>
                <div>
                     <label className={`${classes.description} ${classes.label}`}>5. Temple to temple round back </label> &nbsp; &nbsp;
                     <input 
                        name="m5"
                        value={m5}
                        onChange={e => { e.preventDefault(); setM5(e.target.value) }}
                        required
                    />
                </div>
                <div>
                     <label className={`${classes.description} ${classes.label}`}>6. Nape of Neck </label> &nbsp; &nbsp;
                     <input 
                        name="m6"
                        value={m6}
                        onChange={e => { e.preventDefault(); setM6(e.target.value) }}
                        required
                    />
                </div>
                <p>Ready to take your pictures?</p>
                <div className={classes.button}
                    onClick={() => {
                        setStep(step +1 );
                        setSubStep(subStep + 1);
                    }
                }>
                    Next
                </div>
                </div>
            </div>
        </div>
    )
}
