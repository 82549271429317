import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      dark: '#f2f2f2',
      main: '#FFFFFF',
      light: '#ffee33'
    },
    secondary: {
      dark: '#045f6e',
      main: '#06889E',
      light: '#379fb1'
    },
    darkColor: {
      dark: '#042b41',
      main: '#073E5D',
      light: '#38647d'
    },
  },
})

export default theme
